@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul, ol {
    list-style: revert;
    padding-left: 20;
  }
}

.custom-shadow {
  display: block;
  overflow: visible !important;
  filter:          drop-shadow(0px 5px 15px #222222a8); 
}

.custom-shadow-oak {
display: block;
overflow: visible !important;
filter:          drop-shadow(0px 5px 15px #442e1ea8); 
}

.custom-shadow-apps {
  display: block;
  overflow: visible !important;
  filter:          drop-shadow(0px 1px 5px #00000027); 
  }

.button-yellow {
  background-color: #FFEA00;
}
  
.button-grey {
  background-color: #c7c7c7;
}

.icons {
width: 64px;
}

.highlights {
  width: 96px;
  }

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
}

.logo svg {
  width: 400px;
}

.noscroll { 
  overflow: hidden;
}

h2 {
  font-size: x-large;
  line-height: 1.4;
  padding-top: 20;
  padding-bottom: 5;
  font-weight: 700;
}

h3 {
  font-size: large;
  line-height: 1.4;
  padding-top: 10;
  font-weight: 700;
}

h4 {
  font-size: medium;
  line-height: 1.4;
  padding-top: 10;
  font-weight: 700;
}

.mibreit_CookieConsent {
  width: inherit !important;
  display: block;
  flex-direction: inherit;
}

@media only screen and (max-width: 600px) {
  .mibreit_CookieConsent {
    display: flex;
    flex-direction: column;
  }
}

.mibreit_CookieConsent button {
  padding: 0 !important;
  margin: 0 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  width: inherit !important;
  background-color: #FFFA84;
  color: black;
  border-radius: 4px;
  height: inherit !important;
}

.mibreit_CookieConsent button:first-child {
  background-color: black !important;
  color: white;
  font-weight: 700;
  margin-right: 10px !important;
}

@media only screen and (max-width: 600px) {
  .mibreit_CookieConsent button:first-child {
    margin-right: 0px !important;
    margin-bottom: 5px !important;
  }
}

.mibreit_CookieConsent_Selector {
  margin: 0 !important;
  margin-bottom: 15px !important;
  padding: 10px 0px;
  border-top: rgba(0, 0, 0, 0.5) 1px solid;
  border-bottom: rgba(0, 0, 0, 0.5) 1px solid;
}

.mibreit_CookieConsent_Selector .setter_RN- {
  padding: 0 !important;
  margin: 0 !important;
}

.mibreit_CookieConsent_Info {
  margin: 0px !important;
  padding: 0px !important;
}